


































import {Component, Vue} from 'vue-property-decorator'

@Component({
  metaInfo() { return { title: "Manuel d'utilisation" } }
})
export default class About extends Vue {}
